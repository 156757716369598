@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,700';

// Edit these if there ever is a change of color.
$colortext: rgba(0, 0, 0, 0.75);
$colortextWhite: #ffffff;
$backgroundColor: #ffffff;
$colorButton: #68cef8;

// NEVER change these.
$breakpointMaxMobile: 767;
$breakpointMaxTablet: 991;
$breakpointMinMobile: 768;
$breakpointMinTablet: 992;

$breakpointMaxLaptop: 1600;
$breakpointSmallLaptop: 1280;
$breakpointMinPCBrowsers: 1025;

// Edit these if there is a change of background images.
$backgroundHeader: url('/images/pax-header.jpg');
$backgroundHeaderMobile: url('/images/pax-header-mobile.jpg');

html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  display: table;
  background: #fafafa;
  font-weight: 100;
  font-family: 'Roboto', sans-serif;
}

// General components such as buttons etc.

.General__button{
  background-color: $colorButton;
  color: $colortextWhite;
  border-radius: 125px;
  padding: 15px 30px;
  text-align: center;
  font-size: 16px;
  border: none;
  font-weight: 500;
  box-shadow: 0px 13px 24px -10px rgba(0, 0, 0, 0.6);
  position: relative;
  top: 40px;
  letter-spacing: 0.2px;
  z-index: 2;
  margin-top: 50px;

  &:hover{
    background-color: #63C4EB;
    text-decoration: none;
    color: $colortextWhite;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  &:active{
    text-decoration: none;
    color: $colortextWhite;
  }

  &:focus{
    text-decoration: none;
    color: $colortextWhite;
  }
}

.General__button--second{
  background-color: $colorButton;
  color: $colortextWhite;
  border-radius: 125px;
  padding: 15px 30px;
  text-align: center;
  font-size: 16px;
  border: none;
  font-weight: 500;
  letter-spacing: 0.2px;
  box-shadow: 0px 13px 24px -12px rgba(0, 0, 0, 0.1);
  z-index: 2;
  display: table;
  margin-top: 50px;

  @media(max-width: $breakpointMaxTablet + px){
    top: 0px;
  }

  @media(max-width: $breakpointMaxMobile + px){
  margin-bottom: 250px;
  }

  &:hover{
    background-color: #63C4EB;
    text-decoration: none;
    color: $colortextWhite;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  &:active{
    text-decoration: none;
    color: $colortextWhite;
  }

  &:focus{
    text-decoration: none;
    color: $colortextWhite;
  }
}

.container{

  @media(max-width: $breakpointMaxMobile + px){
    padding: 0px 10px;
  }
}

.Content__centering{
  width: 100%;
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

// End of general components, start of normal styling

.Navbar {
  background-color: transparent;
  padding: 20px 0px;
  top: 0;

  @media(max-width: $breakpointMaxTablet + px){
    margin-bottom: 30px;
  }

  @media(max-width: $breakpointMaxMobile + px){
    padding: 20px;
    margin-bottom: 0px;
  }
}

.Navbar ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content : space-between;
  align-items: center;

  @media(max-width: $breakpointMaxTablet + px){
    padding: 0px 20px;
  }

  @media(max-width: $breakpointMaxMobile + px){
    padding: 0px 10px;
  }
}

.Navbar__appstore{
  &:first-child{
    margin-right: 20px;
  }
}

.Navbar__appstore img{
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.15);
}

.Navbar ul li {
  list-style-type: none;
}

.Downloadapp{
  display: flex;

  @media(max-width: $breakpointMaxMobile + px){
    display: none;
  }
}


.Logo{
  padding: 0px;
}

.Logo__image{

}

// Header

.Header__paxter{
  width: 100%;
  height: 800px;

  @media(max-width: $breakpointSmallLaptop + px){
    height: 650px;
  }

  @media(max-width: $breakpointMaxTablet + px){
    height: 650px;
  }
}

.Header__paxter--image{
  background-image: $backgroundHeader;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;

  @media(max-width: $breakpointMaxMobile + px){
    background-position: center center;
  }
}

.Header__paxter--gradient{
  width: 100%;
  height: 100%;
  background-image: linear-gradient(-180deg, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0.00) 43%);

  @media(max-width: $breakpointMaxMobile + px){
    background-image: linear-gradient(-180deg, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0.00) 50%);
  }
}

.Header__paxter--second-gradient{
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, rgba(0,0,0,0.60) 0%, rgba(0,0,0,0.00) 63%);

  @media(max-width: $breakpointMaxMobile + px){
    background-image: linear-gradient(-90deg, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0.20) 100%);
  }
}

.Header__paxter--second-gradient .container{
  @media(max-width: $breakpointMaxTablet + px){
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
}

.row{
  @media(max-width: $breakpointMaxTablet + px){
    margin-left: 0px;
    margin-right: 0px;
  }
}

.Header__introtext{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content : center;
  align-items: center;
  align-content: center;
  box-sizing: border-box;

  @media(max-width: $breakpointMaxTablet + px){
    padding: 0px 20px;
  }

  @media(max-width: $breakpointMaxMobile + px){
    padding: 0px 10px;
  }
}

.Introtext__content{
  color: $colortextWhite;
  padding: 0px;

  @media(max-width: $breakpointMaxMobile + px){
    padding: 0px 20px;
  }
}

.Introtext__content h1{
  font-size: 50px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;

  @media(max-width: $breakpointMaxMobile + px){
    font-size: 42px;
    font-weight: 500;
    letter-spacing: 0.4px;
  }
}

.Introtext__content p{
  font-size: 20px;
  line-height: 1.6;
  margin: 0px;
  letter-spacing: 0.2px;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  @media(max-width: $breakpointMaxTablet + px){
    padding-right: 25%;
  }

  @media(max-width: $breakpointMaxMobile + px){
    padding-right: 0px;
  }
}

.Introtext__content img {
  height: 100%;
  width: 100%;
  position: relative;
  top: 240px;
  left: -75px;

  @media(max-width: $breakpointSmallLaptop+ px){
    top: 160px;
    left: 0px;
  }
}

//Content block
.Content__block--holder{
  @media(max-width: $breakpointMaxTablet + px){
    width: 100%;
    margin: 0px;
    padding: 0px 20px;
  }

  @media(max-width: $breakpointMaxMobile + px){
    padding: 0px 10px;
  }
}

.Content__block:nth-child(even) .Content__block--item:first-child{
  padding-left: 35px;

  @media(max-width: $breakpointMaxTablet + px){
    padding: 0px;
  }
}


.Content__block{
  background-color: $colortextWhite;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 100px 0px;
  min-height: 500px;

  &:first-child{
    margin-top: 200px;
  }

  &:nth-child(even){
    @media(min-width: $breakpointMinMobile + px) {
      display: flex;
      flex-direction : row-reverse;
    }
  }

  @media(max-width: $breakpointMaxTablet + px){
    margin: 20px 0px 0px 0px;
    display: flex;
    min-height: 100%;

    &:first-child{
      margin-top: 20px;
    }

    &:last-child{
      margin-bottom: 20px;
    }
  }

  @media(max-width: $breakpointMaxMobile + px){
    margin-top: 475px;
    max-height: 800px;
    display        : flex;
    flex-direction : column-reverse;
    justify-content : flex-end;
    padding: 0px 20px;

    &:first-child{
      margin: 10px 0px 0px 0px;
    }

    &:last-child{
      margin-bottom: 475px;
    }
  }

  @media(max-width: 360px){
    max-height: 600px;
    margin-top: 400px;

    &:last-child{
      margin-bottom: 400px;
    }
  }

  @media(max-width: 320px){
    margin-top: 320px;

    &:last-child{
      margin-bottom: 320px;
    }
  }
}

.Content__block--item{
  color: $colortext;
  margin-top: 50px;

  @media(max-width: $breakpointMaxTablet + px){
  }

  @media(max-width: $breakpointMaxMobile + px){
    margin-top: 0px;
    float: none;
  }
}


.Content-block--phone{
  @media(max-width: $breakpointMaxTablet + px){
    height: auto;
    padding: 0px;
    position: relative;
    margin-bottom: 50px;
  }

  @media(max-width: $breakpointMaxMobile + px){
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 0px;
  }
}

.Content__block--item-text{
  padding: 0px 30px;

  @media(max-width: $breakpointMaxTablet + px){
    padding: 0px
  }

  @media(max-width: $breakpointMaxMobile + px){
    margin: 0px;
  }
}

.Content__block--item img {
  width    : 100%;
  position : absolute;
  right    : 0;

  @media(max-width: $breakpointMaxTablet + px){
    position: relative;
    height: auto;
  }

  @media(max-width: $breakpointMaxMobile + px){
    position: absolute;
    height: auto;
    top: -200px;
  }
}

.Last-phone{
  @media(max-width: $breakpointMaxMobile + px){
    top: -185px!important;
  }
}

.Content__block--item h2{
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
  margin-top: 30px;

  @media(max-width: $breakpointMaxMobile + px){
   margin-bottom: 10px;
  }
}

.Content__block--item p {
  font-size: 18px;
  line-height: 1.8;
  letter-spacing: 0.2px;
}


// Footer

.Footer{
  background-color: $backgroundColor;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 80px;
  margin-top: 100px;

  @media(max-width: $breakpointMaxTablet + px){
    margin-top: 0px;
  }

  @media(max-width: $breakpointMaxMobile + px){
    position: relative;
    bottom: 0;
  }
}

.Footer .container .row,
.Footer .container{
  height: 100%;
}

.Footer .container .row .col-lg-12{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content : space-between;
  align-items: center;
  align-content: center;
}

.Footer .container .row .col-lg-12 span,
.Footer .container .row .col-lg-12 a{
  font-size: 14px;
  letter-spacing: 0.1px;
  color: $colortext;
}

.Footer .container .row .col-lg-12 a{
  text-align: right;

  &:hover{
    text-decoration: none;
  }

  &:focus{
    text-decoration: none;
  }

  &:active{
    text-decoration: none;
  }
}